<template>
  <div class="container">
    <div class="right"
         :style="fullscreen ? 'width: 118%' : 'width: 100%'">
      <!-- 公司业务 -->
      <div class="produce">
        <!-- <div class="produce_title">公司产品</div> -->
        <div class="top_title"
             style="width: 100">
          <div class="produce_titleList">
            <div class="titleBox"
                 style="display: flex;overflow-x: auto;">
              <div :class="
                item.ptId == currentValue
                  ? 'produce_title title_border'
                  : 'produce_title'
              "
                   @click="changeTitle(item.ptId)"
                   v-for="(item, index) in typeList"
                   :key="index">
                {{ item.ptTypeName }}
              </div>
            </div>
          </div>
        </div>

        <div class="produce_List"
             v-if="pageTotal > 0">
          <div style="margin-right: 30px; text-align:right; padding-top:30px;">
            <el-date-picker :editable="false"
                            v-model="query.pYear"
                            type="year"
                            placeholder="选择年份"
                            :picker-options="limitYear"
                            @change="changeDate">
            </el-date-picker>
          </div>
          <van-list v-model="loading"
                    :finished="finished"
                    finished-text="我是有底线的"
                    @load="onLoad"
                    class="vanlist"
                    :immediate-check="false">
            <div v-for="(item, index) in List"
                 :key="index"
                 class="produce_itemList">
              <div class="produce_item">
                <div class="img"
                     v-if="item.plogo != ''">
                  <img :src="item.plogo"
                       alt=""
                       @click="ToDetails(item.pid)" />
                </div>
                <div class="img"
                     v-else>
                  <img src="../../assets/image/produce/produce_1.png"
                       alt=""
                       @click="ToDetails(item.pid)" />
                </div>
                <div class="title">
                  <!-- <div class="border"></div>
              <div class="title2">{{ item.pname}}</div> -->
                </div>
              </div>

              <div class="title">
                <!-- 已上线 -->
                <div class="border_bg"
                     v-if="item.ponline == 1">
                  <div class="border"></div>
                </div>
                <!-- 未上线 -->
                <div class="border_bg2"
                     v-if="item.ponline == 0">
                  <div class="border2"></div>
                </div>
                <div class="title2"
                     style="
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  ">
                  {{ item.pnickName }}
                </div>
              </div>
            </div>
            <span style="width:215px"></span>
            <span style="width:215px"></span>
            <span style="width:215px"></span>
            <span style="width:215px"></span>
            <span style="width:215px"></span>
            <span style="width:215px"></span>
            <span style="width:215px"></span>
          </van-list>
          <!-- <div style="  padding-bottom: 40px;color: #969799; font-size:12px;margin-top:20px">丹道提供技术支持</div> -->
          <Fotter></Fotter>
        </div>
        <div v-else
             class="noData">暂无内容</div>
        <!-- <div class="pagination">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageNum"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
          ></el-pagination>
        </div> -->
        <!-- <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="(item,indexs) in List" :key="indexs" :title="item" />
        </van-list> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getProductList, getProductTypeList } from "../../utils/api.js";
import Fotter from "../../components/fotter.vue";
export default {
  components:{
    Fotter,
  },
  data () {

    return {
      limitYear: {
        disabledDate: (time) => {
          let minYear = new Date().getFullYear() - 10
          return time > Date.now() || time.getTime() < new Date(JSON.stringify(minYear))
        }
      },
      loading: false,
      finished: false,
      List: [],
      typeList: [],
      currentValue: 1,
      query: {
        pageNum: 1,
        pageSize: 20,
        ptId: 0,
        uaId: 1
      },
      pageTotal: 0,
      totalPage: 0,
      fullscreen: false,

      value: "",
    };
  },
  created () {
    this.getProductTypeList();
    // this.getProductList();
    console.log(this.$route);
  },
  methods: {
    // 移动端下拉刷新分页列表
    onLoad () {
      // 增加计时器，确保每次请求的数据不相同
      setTimeout(() => {
        this.loading = true;
        this.query.pageNum += 1;
        this.$set(this.query);

        console.log(this.query.pageNum);
        this.getProductList();
      }, 500);
    },
    getProductTypeList () {
      getProductTypeList({ uaId: 1 }).then((res) => {
        if (res.code == 20000) {
          console.log(res);
          this.typeList = res.data;
          this.query.ptId = res.data[0].ptId;
          this.getProductList();
        }
        //  this.ptTypeList = this.typeList.forEach((item) => {
        //     console.log(item);
        //   })
      });
    },
    getProductList () {
      this.$set(this.query);
      getProductList(this.query).then((res) => {
        if (res.code == 20000) {
          console.log(res);
          this.loading = false;
          this.totalPage = res.data.totalPage;
          if (res.data.dataList.length > 0) {
            console.log(this.List.length);
            this.List = this.List.concat(res.data.dataList);
            this.pageTotal = res.data.totalRecord;
            if (this.List.length == res.data.totalRecord) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            console.log(this.List);
          } else {
            this.finished = true;
          }
        }
      });
    },
    // //将选中的日期转换成两位数格式
    dateFormat (fmt, date) {
      let ret;
      let opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    changeDate () {
      // if (this.query.pYear != ""&&this.query.pYear != null) {
      //   this.query.pYear = this.dateFormat("YYYY", this.query.pYear);
      //   console.log(this.query.pYear);
      //    this.getProductList();
      // }else{
      if (this.query.pYear != "" && this.query.pYear != null) {
        this.query.pYear = this.dateFormat("YYYY", this.query.pYear);

      }
      console.log(this.query.pYear);
      this.List = []
      this.getProductList();
      // }
    },
    //切换title
    changeTitle (e) {
      console.log(e);
      this.currentValue = e;
      this.query.pYear = "";
      this.query.ptId = e;
      this.query.pageNum = 1;
      this.List.length = 0;
      this.List = [];
      this.getProductList();
    },
    ToDetails (id) {
      console.log(id);
      this.$router.push({ path: "/details", query: { id: id } });
    },
  },
};
</script>

<style  scoped>
.container {
  background: #fff;
  width: 100%;
  height: 100vh;
}
/deep/ .van-list__finished-text,
.van-list__loading {
  width: 100% !important;
  height: 60px !important;
  font-size: 20px !important;
  line-height: 80px !important;
}
.right {
  width: 100%;
  height: 100vh;
  background: #fefefe;
  position: absolute;
  top: 0;
  right: 0;
}
.bgImg {
  width: 100%;
  height: 180px;
  background: url("../../assets/image/bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 60px;
  padding-left: 45px;
  box-sizing: border-box;
}
.bgImg2 {
  width: 100%;
  height: 180px;
  background: url("../../assets/image/introduce_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 60px;
  padding-left: 45px;
  box-sizing: border-box;
}
.bgImg div {
  color: #fff;
  text-align: left;
}
.bgImg_title {
  font-size: 35px;
  height: 50px;
}
.bgImg_title2 {
  font-size: 22px;
  height: 40px;
  color: #fff;
  width: 140px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.bgImg_title3 {
  font-size: 28px;
  margin-left: -45px;
  height: 40px;
  color: #fff;
}
.noData {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gray;
  font-size: 16px;
}

.produce {
  width: 100%;
  height: 100%;
  background: #fefefe;
  margin-top: 6%;
}
.produce_List {
  /* height:80%;
  overflow:scroll; */
  /* padding: 30px 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-image: url("https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/1872111773928.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  background-image: url("https://dandaoipad.oss-cn-hangzhou.aliyuncs.com/dandaoipad/1872111773928.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  width: 100%;
  /* overflow: hidden; */
}
.vanlist {
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
}
.produce_title {
  font-size: 24px;

  /* width: 90px; */
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
  color: #333232;
  padding-bottom: 3px;
  box-sizing: border-box;
}
.title_border {
  border-bottom: 3px solid #59a8ee;
  font-weight: 550;
}
.top_title {
  /* position: fixed; */
  width: 100%;
  background: #fefefe;
  z-index: 22;
}
.produce_titleList {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: fixed;
  background: #fefefe;
  z-index: 22; */
  /* margin-bottom: 10%; */
}
.date {
  color: #979797;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.produce_itemList {
  width: 185px;
  height: 205px;
  font-size: 14px;
  font-weight: 550;
  margin: 20px 15px 20px 15px;
}
.produce_item {
  width: 185px;
  height: 180px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0 0px 9px rgb(92 87 87);
  border-radius: 0.08rem;
  background: #fff;
}
.produce_item .img {
  width: 185px;
  height: 180px;
  border-radius: 0.08rem;
}
.produce_item img {
  width: 185px;
  height: 180px;
  border-radius: 0.08rem;
}
.produce_itemList .title {
  font-size: 18px;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border_bg {
  background: #fff;
  border: 2px solid #59a8ee;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 1px;
  box-sizing: border-box;
  margin-right: 10px;
}
.border {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #59a8ee;
  margin-right: 20px;
}
.titleBox::-webkit-scrollbar {
  display: none;
}
.border_bg2 {
  background: #fff;
  border: 2px solid #d2d2d2;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 1px;
  box-sizing: border-box;
  margin-right: 10px;
}
.border2 {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d2d2d2;
  margin-right: 20px;
}
.pagination {
  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
/deep/ .el-input__inner {
  border: 0.01333rem solid #979797;
}
</style>